import React from "react";
import { handleDownloadBtnClick } from "helpers";

const Price = () => {
  return (
    <section className="price" id="price">
      <div className="container-lg g-0">
        <div className="row g-0">
          <div className="col-sm-12 col-md-12 order-2 order-lg-1 col-lg-6 price__left">
            <div className="mb-4">
              <span className="price__left__txt">
                * İllik
              </span>
              <span className="price__left__price">9.99 AZN</span>
            </div>
            <h1 className="price__left__title">
              Siz də Əlimərdan kişi kimi təsərrüfatınızı sərfəli idarə etmək
              istəyirsiniz?
            </h1>
            <div className="arrow_container">
              <img
                className="arrow"
                alt="arrow"
                src={require("assets/images/icons/arrow.svg").default}
              />
            </div>
            <div className="download">
              <div className="download__qr d-none d-lg-block">
                <img alt="qr-code" src={require("assets/images/qr-code.png")} />
              </div>
              <div className="download__icon">
                <img
                  alt="google-play"
                  src={require("assets/images/google-play.svg").default}
                />
                <p className="download__icon__txt d-none d-lg-block">
                  🤩 Mobil tətbiqi asan endirmək üçün QR code - u oxudun.
                </p>
              </div>
            </div>
            <div className="d-block d-lg-none">
              <button
                className="btn btn-black"
                onClick={handleDownloadBtnClick}
              >
                Yüklə
              </button>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 order-1 order-lg-2 col-lg-6 farmer">
            <img alt="farmer" src={require("assets/images/farmer.jpg")} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Price;
