import "./App.css";
import Main from "containers/Main";
import { Menu } from "components/Menu";
import About from "containers/About";
import Functionality from "containers/Functionality";
import Price from "containers/Price";
import Footer from "components/Footer";
import Alert from "components/Alert";

function App() {
  return (
    <div className="App">
      <Menu />
      <Main />
      <About />
      <Functionality />
      <Price />
      <Footer />
      <Alert />
    </div>
  );
}

export default App;
